import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { first } from 'rxjs';
import { AuthService } from '@/app/services';
import { ApiError } from '@/types';

@Component({
    selector: 'app-sign-up',
    standalone: true,
    imports: [ReactiveFormsModule, NgOptimizedImage, NgIf, NgClass, RouterLink],
    templateUrl: './sign-up.component.html',
    styleUrl: './sign-up.component.scss',
})
export class SignUpComponent {
    isLoading = false;
    error = '';
    form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
    });

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        if (this.authService.token) {
            this.router.navigate(['/']);
        }
    }

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;
        this.authService
            .register({
                email: this.form.controls.email.value!,
                password: this.form.controls.password.value!,
            })
            .pipe(first())
            .subscribe({
                next: () => {
                    this.router.navigate(['/']);
                },
                error: ({ error }: { error: ApiError }) => {
                    this.error = error?.error?.message || JSON.stringify(error);
                    this.isLoading = false;
                },
            });
    }
}
