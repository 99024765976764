import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService, TrackingService, UserService } from '@/app/services';
import { first, Subject, takeUntil } from 'rxjs';
import { DatePipe, JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Tracking, User } from '@/app/models';
import { ApiError, ResponseWrapper } from '@/types';
import { TransactionType } from '@/enums';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomValidators } from '@/app/utils';

@Component({
    selector: 'app-change-password',
    standalone: true,
    imports: [RouterLink, NgIf, JsonPipe, NgClass, DatePipe, NgForOf, FormsModule, ReactiveFormsModule],
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    isLoading = false;
    submitted = false;
    public user: User | null = null;
    error: string = '';
    form = this.formBuilder.group(
        {
            password: ['', [Validators.required, Validators.minLength(8)]],
            repeatPassword: ['', [Validators.required]],
            currentPassword: ['', [Validators.required]],
        },
        {
            validators: [CustomValidators.match('password', 'repeatPassword')],
        },
    );

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
    ) {}

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;

        this.authService
            .login({
                email: this.user?.email!,
                password: this.form.controls.currentPassword.value!,
                silent: true,
            })
            .pipe(first())
            .subscribe({
                next: () => {
                    this.userService
                        .changePassword(this.form.controls.password.value!)
                        .pipe(first())
                        .subscribe({
                            next: () => {
                                this.submitted = true;
                                this.isLoading = false;
                                this.form.reset();
                            },
                            error: ({ error }: { error: ApiError }) => {
                                this.error = error?.error?.message || JSON.stringify(error);
                                this.isLoading = false;
                            },
                        });
                },
                error: () => {
                    this.error = 'Incorrect Password';
                    this.isLoading = false;
                    this.form.reset();
                },
            });
    }

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly TransactionType = TransactionType;
}
