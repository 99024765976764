<h3 class="my-5 text-center">Change password</h3>
<form id="change-password-form" class="col-md-5 mx-auto mb-5" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="error" class="alert alert-danger mb-4">{{ error }}</div>
    <div *ngIf="submitted" class="alert alert-success mb-4">The new password has been set.</div>
    <div class="mb-4">
        <label class="form-label" for="password">New password</label>
        <input
            class="form-control py-2"
            [ngClass]="{
                'is-invalid': form.controls.password.touched && form.controls.password.errors
            }"
            id="password"
            formControlName="password"
            type="password"
            required
        />
        <div class="invalid-feedback" *ngIf="form.controls.password.hasError('required')">Required field</div>
        <div class="invalid-feedback" *ngIf="form.controls.password.hasError('minlength')">
            Password must have at least 8 characters
        </div>
    </div>
    <div class="mb-4">
        <label class="form-label" for="repeatPassword">Repeat new password</label>
        <input
            class="form-control py-2"
            [ngClass]="{
                'is-invalid': form.controls.repeatPassword.touched && form.controls.repeatPassword.errors
            }"
            id="repeatPassword"
            formControlName="repeatPassword"
            type="password"
            required
        />
        <div class="invalid-feedback" *ngIf="form.controls.repeatPassword.hasError('required')">Required field</div>
        <div class="invalid-feedback" *ngIf="form.controls.repeatPassword.hasError('matching')">
            The entered passwords do not match
        </div>
    </div>
    <div class="mb-4">
        <label class="form-label" for="repeatPassword">Current password</label>
        <input
            class="form-control py-2"
            [ngClass]="{
                'is-invalid': form.controls.currentPassword.touched && form.controls.currentPassword.errors
            }"
            id="currentPassword"
            formControlName="currentPassword"
            type="password"
            required
        />
        <div class="invalid-feedback" *ngIf="form.controls.currentPassword.hasError('required')">Required field</div>
    </div>
    <button class="btn py-2 btn-success w-100 mt-4 mb-3" type="submit" [disabled]="isLoading || !form.valid">
        <span *ngIf="isLoading" class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </span>
        <span *ngIf="!isLoading">Submit</span>
    </button>
</form>
