<div class="bm-auth justify-content-center mx-auto col-sm-10 col-md-8 col-lg-5 p-5 text-white">
    <h3 class="text-center mb-5">Set new password</h3>
    <div id="form-wrapper">
        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
        <div *ngIf="submitted" class="alert alert-success">
            The new password has been set, soon you will be redirected to the login page
        </div>
        <form id="reset-password-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-4">
                <label class="form-label" for="password">New password</label>
                <input
                    class="form-control py-2 border-0"
                    [ngClass]="{
                        'is-invalid': form.controls.password.touched && form.controls.password.errors
                    }"
                    id="password"
                    formControlName="password"
                    type="password"
                    required
                />
                <div class="invalid-feedback" *ngIf="form.controls.password.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.password.hasError('minlength')">
                    Password must have at least 8 characters
                </div>
            </div>
            <div class="mb-4">
                <label class="form-label" for="repeatPassword">Repeat new password</label>
                <input
                    class="form-control py-2 border-0"
                    [ngClass]="{
                        'is-invalid': form.controls.repeatPassword.touched && form.controls.repeatPassword.errors
                    }"
                    id="repeatPassword"
                    formControlName="repeatPassword"
                    type="password"
                    required
                />
                <div class="invalid-feedback" *ngIf="form.controls.repeatPassword.hasError('required')">
                    Required field
                </div>
                <div class="invalid-feedback" *ngIf="form.controls.repeatPassword.hasError('matching')">
                    The entered passwords do not match
                </div>
            </div>
            <button class="btn py-2 btn-success w-100 mt-4 mb-3" type="submit" [disabled]="isLoading || !form.valid">
                <span *ngIf="isLoading" class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </span>
                <span *ngIf="!isLoading">Submit</span>
            </button>
        </form>
    </div>
</div>
