<ul class="nav nav-tabs border-0">
    <li class="nav-item">
        <a
            routerLink="/"
            class="nav-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
                exact: true
            }"
            aria-current="page"
            href="#"
            >Credits</a
        >
    </li>
    <li class="nav-item">
        <a routerLink="/trackings" class="nav-link" routerLinkActive="active" href="#">Trackings</a>
    </li>
    <li class="nav-item">
        <a routerLink="/change-password" class="nav-link" routerLinkActive="active" href="#">Change Password</a>
    </li>
    <li class="nav-item ms-auto">
        <button class="nav-link" (click)="logout()">Logout</button>
    </li>
</ul>
