import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import qs from 'qs';

import { APP_CONFIG } from '@/environments/environment';
import { ResponseWrapper } from '@/types';
import { Transaction } from '@/app/models';

@Injectable({ providedIn: 'root' })
export class TransactionService {
    constructor(private http: HttpClient) {}

    getTransactions(params: { page: number, limit: number }): Observable<ResponseWrapper<Transaction[]>> {
        const searchParams = {
            sort: ['-date_created'],
            limit: params.limit,
            page: params?.page || 1,
            fields: '*,tracking.*',
            meta: '*'
        };

        return this.http.get<ResponseWrapper<Transaction[]>>(
            `${APP_CONFIG.apiUrl}/items/transactions?${qs.stringify(searchParams)}`,
            {
                withCredentials: true,
            },
        );
    }
}
