import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import qs from 'qs';

import { APP_CONFIG } from '@/environments/environment';
import { ResponseWrapper } from '@/types';
import { Tracking } from '@/app/models';

@Injectable({ providedIn: 'root' })
export class TrackingService {
    constructor(private http: HttpClient) {}

    getTrackings(params: { page: number; limit: number }): Observable<ResponseWrapper<Tracking[]>> {
        const searchParams = {
            fields: '*',
            meta: '*',
            sort: ['-date_created'],
        };

        return this.http.get<ResponseWrapper<Tracking[]>>(
            `${APP_CONFIG.apiUrl}/items/trackings?${qs.stringify(searchParams)}`,
            {
                withCredentials: true,
            },
        );
    }

    getTracking(id: string): Observable<ResponseWrapper<Tracking>> {
        return this.http.get<ResponseWrapper<Tracking>>(`${APP_CONFIG.apiUrl}/items/trackings/${id}`, {
            withCredentials: true,
        });
    }

    getTrackingReceipt(id: string): Observable<Blob> {
        return this.http.get(
            `${APP_CONFIG.apiUrl}/blurmaker/trackings/${id}/receipt`,
            {
                params: {
                    tz: Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                responseType: 'blob',
                withCredentials: true,
            },
        );
    }
}
