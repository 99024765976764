import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIf } from '@angular/common';
import { AuthService } from '@/app/services';

@Component({
    selector: 'app-nav',
    standalone: true,
    imports: [RouterLink, NgIf, RouterLinkActive],
    templateUrl: './nav.component.html',
    styleUrl: './nav.component.scss',
})
export class NavComponent {
    constructor(
        private authService: AuthService,
    ) {}

    logout() {
        this.authService.logout();
        window.location.reload();
    }
}
