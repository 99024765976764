import { Routes } from '@angular/router';
import { AuthGuard } from '@/app/guards';
import { CreditsComponent } from './components/credits/credits.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { RemindPasswordComponent } from './components/remind-password/remind-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TrackingsComponent } from './components/trackings/trackings.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TrackingComponent } from '@/app/components/tracking/tracking.component';
import { TermsComponent } from '@/app/components/terms/terms.component';

export const routes: Routes = [
    {
        path: '',
        component: CreditsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'trackings',
        component: TrackingsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'trackings/:id',
        component: TrackingComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'sign-in',
        component: SignInComponent,
    },
    {
        path: 'sign-up',
        component: SignUpComponent,
    },
    {
        path: 'remind-password',
        component: RemindPasswordComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'terms',
        component: TermsComponent,
    },
    { path: '**', redirectTo: '' },
];
