import {Component, OnDestroy, OnInit} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { AuthService, UserService } from '@/app/services';
import { User } from '@/app/models';
import {NavComponent} from '@/app/components/nav/nav.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, NgOptimizedImage, RouterLink, NgIf, NavComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Blur Maker';
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = false;
    public user: User | null = null;

    constructor(
        private userService: UserService,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
        this.authService.token$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((token) => {
            if (!token) {
                return;
            }
            this.isLoading = true;
            this.userService
                .getUser()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.isLoading = false;
                });
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
