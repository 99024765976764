import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TrackingService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { DatePipe, JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Tracking } from '@/app/models';
import { ResponseWrapper } from '@/types';
import { TransactionType } from '@/enums';
import {extractFileName, secondsToTime} from '@/app/utils';

@Component({
    selector: 'app-tracking',
    standalone: true,
    imports: [RouterLink, NgIf, JsonPipe, NgClass, DatePipe, NgForOf],
    templateUrl: './tracking.component.html',
    styleUrl: './tracking.component.scss',
})
export class TrackingComponent implements OnDestroy, OnInit {
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = false;
    public pdfIsLoading: boolean = false;
    public tracking: Tracking | null = null;
    public trackingId: string | null;
    public totalTime: string | undefined;
    public trackedTime: string | undefined;
    constructor(
        private trackingService: TrackingService,
        private route: ActivatedRoute,
    ) {
        this.trackingId = this.route.snapshot.paramMap.get('id');
        this.route.queryParamMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paramMap) => {
            const openPDF = paramMap.get('openPDF');
            if (openPDF) {
                this.openPDF();
            }
        });
    }

    loadTracking() {
        if (!this.trackingId) {
            return;
        }
        this.isLoading = true;
        this.trackingService
            .getTracking(this.trackingId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: ResponseWrapper<Tracking>) => {
                    const { data } = response;
                    this.tracking = data;
                    this.totalTime = secondsToTime(parseFloat(data.total_seconds));
                    this.trackedTime = secondsToTime(data.frames / parseFloat(data.frame_rate));
                    this.isLoading = false;
                },
                error: () => {
                    this.tracking = null;
                    this.isLoading = false;
                },
            });
    }

    downloadPDF() {
        this.pdfIsLoading = true;
        this.trackingService.getTrackingReceipt(this.trackingId!).subscribe((blob) => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `${this.trackingId}.pdf`;
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
            this.pdfIsLoading = false;
        });
    }

    openPDF() {
        this.pdfIsLoading = true;
        this.trackingService.getTrackingReceipt(this.trackingId!).subscribe((blob) => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.pdfIsLoading = false;
        });
    }

    ngOnInit() {
        this.loadTracking();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly TransactionType = TransactionType;
    protected readonly extractFileName = extractFileName;
}
