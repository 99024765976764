<div class="bm-auth justify-content-center mx-auto col-sm-10 col-md-8 col-lg-5 p-5 text-white">
    <h3 class="text-center mb-5">Sign up</h3>
    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    <form id="sign-up-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="mb-4">
            <label class="form-label" for="email">Email address</label>
            <input
                class="form-control py-2 border-0"
                id="email"
                type="text"
                formControlName="email"
                [ngClass]="{
                    'is-invalid': form.controls.email.touched && form.controls.email.errors
                }"
            />
            <div class="invalid-feedback" *ngIf="form.controls.email.hasError('required')">Required field</div>
            <div class="invalid-feedback" *ngIf="form.controls.email.hasError('email')">Incorrect email address</div>
        </div>
        <div class="mb-4">
            <label class="form-label" for="password">Password</label>
            <input
                type="text"
                class="form-control py-2 border-0"
                type="password"
                id="password"
                formControlName="password"
                [ngClass]="{
                    'is-invalid': form.controls.password.touched && form.controls.password.errors
                }"
                minlength="8"
            />
            <div class="invalid-feedback" *ngIf="form.controls.password.hasError('required')">Required field</div>
            <div class="invalid-feedback" *ngIf="form.controls.password.hasError('minlength')">
                Password must have at least 8 characters
            </div>
        </div>
        <button class="btn py-2 btn-success w-100 mt-4 mb-3" type="submit" [disabled]="isLoading || !this.form.valid">
            <span *ngIf="isLoading" class="spinner-border">
                <span class="visually-hidden">Loading...</span>
            </span>
            <span *ngIf="!isLoading">Sign Up</span>
        </button>
        <p class="text-center mt-4">
            Already have an account? <a class="text-white" [routerLink]="['/sign-in']">Sign In</a>
        </p>
    </form>
</div>
