<div class="container container-lg">
    <div class="d-flex justify-content-center py-5">
        <a [routerLink]="['/']">
            <img *ngIf="!user" ngSrc="/assets/logo.png" width="300" height="109" alt="BlurMaker" priority />
            <img *ngIf="user" ngSrc="/assets/logo_inside.png" width="200" height="72" alt="BlurMaker" priority />
        </a>
    </div>
    <div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
        <div class="spinner-border text-light">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="mb-5">
        <ng-container *ngIf="user">
            <app-nav />
            <div class="card border-0">
                <div class="card-body justify-content-center p-4">
                    <router-outlet *ngIf="!isLoading" />
                </div>
            </div>
        </ng-container>
        <router-outlet *ngIf="!isLoading && !user" />
    </div>
</div>
