import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@/app/services';
import { Token } from '@/enums';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const authToken = this.authService.token;
        const queryToken = route.queryParamMap.get('token');
        const accessToken = route.queryParamMap.get('accessToken');

        if (accessToken) {
            localStorage.setItem(Token.ACCESS, accessToken);
            this.authService.setToken(accessToken);
            const queryParams = { ...route.queryParams };
            delete queryParams['accessToken'];

            this.router.navigate([state.url.split('?')[0]], {
                queryParams: queryParams,
            }).then(() => {
                window.location.reload();
            });
            return false;
        }

        if (authToken) {
            return true;
        } else if (queryToken) {
            this.router.navigate(['/reset-password'], {
                queryParams: { token: queryToken },
            });
            return false;
        } else if (!accessToken) {
            this.router.navigate(['/sign-in'], {
                queryParams: { returnUrl: state.url },
            });
            return false;
        }
        return false;
    }
}
