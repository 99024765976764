import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_CONFIG } from '@/environments/environment';
import { User } from '@/app/models';
import { map } from 'rxjs/operators';
import qs from 'qs';

@Injectable({ providedIn: 'root' })
export class UserService {
    private userSubject: BehaviorSubject<User | null>;
    public user$: Observable<User | null>;
    constructor(private http: HttpClient) {
        this.userSubject = new BehaviorSubject<User | null>(null);
        this.user$ = this.userSubject.asObservable();
    }

    public get user(): User | null {
        return this.userSubject.value;
    }

    getUser(): Observable<any> {
        const searchParams = {
            fields: 'id,email,wallet.*',
        };
        return this.http
            .get<any>(`${APP_CONFIG.apiUrl}/users/me?${qs.stringify(searchParams)}`, {
                withCredentials: true,
            })
            .pipe(
                map((user) => {
                    this.userSubject.next(user.data);
                }),
            );
    }

    changePassword(password: string) {
        return this.http.patch<User>(
            `${APP_CONFIG.apiUrl}/users/me`,
            { password },
            {
                withCredentials: true,
            },
        );
    }
}
