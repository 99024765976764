<div class="bm-auth justify-content-center mx-auto col-sm-10 col-md-8 col-lg-5 p-5 text-white">
    <h3 class="text-center mb-5">Remind password</h3>
    <div id="form-wrapper">
        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
        <div *ngIf="submitted" class="alert alert-success">
            If the email address you provided is associated with an account in our system, we have sent a password reset
            link to that address. Please check your email inbox and follow the instructions.
        </div>
        <form id="remind-password-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label class="form-label" for="email">Email address</label>
                <input
                    class="form-control py-2 border-0"
                    id="email"
                    type="text"
                    formControlName="email"
                    [ngClass]="{
                        'is-invalid': form.controls.email.touched && form.controls.email.errors
                    }"
                    required
                />
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('email')">
                    Incorrect email address
                </div>
            </div>
            <button class="btn py-2 btn-success w-100 mt-4 mb-3" type="submit" [disabled]="isLoading || !form.valid">
                <span *ngIf="isLoading" class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </span>
                <span *ngIf="!isLoading">Submit</span>
            </button>
            <p class="text-center mt-4">
                <a class="text-white" [routerLink]="['/sign-in']">Sign In</a>
            </p>
        </form>
    </div>
</div>
