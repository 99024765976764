import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { AuthService } from '@/app/services';
import { first } from 'rxjs';
import { ApiError } from '@/types';

@Component({
    selector: 'app-sign-in',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgOptimizedImage, RouterLink, NgIf, NgClass],
    templateUrl: './sign-in.component.html',
    styleUrl: './sign-in.component.scss',
})
export class SignInComponent {
    isLoading = false;
    error = '';
    form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
    });
    showPassword = false;
    returnUrl: string = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        if (this.authService.token) {
            this.router.navigate(['/ideas']);
        }
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/ideas';
    }

    private splitUrlAndParams(url: string) {
        const [baseUrl, queryString] = url.split('?');
        const params: { [key: string]: string } = {};

        if (queryString) {
            const queryArray = queryString.split('&');
            queryArray.forEach((param) => {
                const [key, value] = param.split('=');
                params[key] = decodeURIComponent(value);
            });
        }

        return {
            baseUrl,
            params,
        };
    }

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;
        this.authService
            .login({
                email: this.form.controls.email.value!,
                password: this.form.controls.password.value!,
            })
            .pipe(first())
            .subscribe({
                next: () => {
                    const { baseUrl, params } = this.splitUrlAndParams(decodeURI(this.returnUrl));
                    this.router.navigate([baseUrl], {
                        queryParams: params,
                    });
                    this.isLoading = false;
                },
                error: ({ error }: { error: ApiError }) => {
                    this.error = error?.error?.message || JSON.stringify(error);
                    this.isLoading = false;
                },
            });
    }

    onEyeClicked() {
        this.showPassword = !this.showPassword;
    }
}
