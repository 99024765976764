import { Component } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-terms',
    standalone: true,
    imports: [NgIf, NgClass],
    templateUrl: './terms.component.html',
    styleUrl: './terms.component.scss',
})
export class TermsComponent {}
