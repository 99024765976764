<h3 class="mt-2 mb-5">Tracking - {{ extractFileName(tracking?.file_path || '') }}</h3>
<div *ngIf="tracking">
    <dl class="row mt-4">
        <dt class="col-sm-3">Tracking Date</dt>
        <dd class="col-sm-9">
            {{ tracking.date_created | date: 'medium' }}
        </dd>

        <dt class="col-sm-3">Name of video used in Project</dt>
        <dd class="col-sm-9">
            {{ extractFileName(tracking.file_path) }}
        </dd>

        <dt class="col-sm-3">Duration of Video</dt>
        <dd class="col-sm-9">
            {{ totalTime }}
        </dd>

        <dt class="col-sm-3">Tracked Time</dt>
        <dd class="col-sm-9">
            {{ trackedTime }}
        </dd>

        <dt class="col-sm-3">Internal Tracking Number</dt>
        <dd class="col-sm-9">
            {{ tracking.id }}
        </dd>

        <dt class="col-sm-3">Receipt</dt>
        <dd class="col-sm-9">
            <button class="btn btn-sm btn-primary" (click)="downloadPDF()" [disabled]="pdfIsLoading">
                <span *ngIf="!pdfIsLoading">Download PDF</span>
                <span *ngIf="pdfIsLoading">Loading...</span>
            </button>
        </dd>
    </dl>
</div>

<div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
    <div class="spinner-border">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
