import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '@/app/services';
import { APP_CONFIG } from '@/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.token;
        const isLoggedIn = token;
        const isApiUrl = request.url.startsWith(APP_CONFIG.apiUrl);
        const isRefreshRequest = request.url.includes('refresh');
        const isLoginRequest = request.url.includes('login');
        const isSignUpRequest = request.url.includes('users');
        const isMeRequest = request.url.includes('users/me');

        if (isLoggedIn && isApiUrl && !isRefreshRequest && !isLoginRequest && (!isSignUpRequest || isMeRequest)) {
            request = this.addToken(request, token);
        }

        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && !isRefreshRequest && error.status === 401 && !isLoginRequest) {
                    return this.handle401Error(request, next);
                }

                return throwError(() => error);
            }),
        );
    }

    private addToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
        return token ? request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        }) : request.clone();
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;

            return this.authService.refresh().pipe(
                switchMap(() => {
                    this.isRefreshing = false;
                    return next.handle(this.addToken(request, this.authService.token));
                }),
                catchError((error) => {
                    this.isRefreshing = false;
                    this.authService.logout();
                    return throwError(() => error);
                }),
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token !== null),
                take(1),
                switchMap(() => {
                    return next.handle(this.addToken(request, this.authService.token));
                }),
            );
        }
    }
}
