<div class="bm-auth justify-content-center mx-auto col-sm-10 col-md-8 col-lg-5 p-5 text-white">
    <h3 class="text-center mb-5">Sign in to your account</h3>
    <div id="form-wrapper">
        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
        <form id="sign-in-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-4">
                <label class="form-label" for="email">Email address</label>
                <input
                    class="form-control py-2 border-0"
                    id="email"
                    type="text"
                    formControlName="email"
                    [ngClass]="{
                        'is-invalid': form.controls.email.touched && form.controls.email.errors
                    }"
                    required
                />
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('email')">
                    Incorrect email address
                </div>
            </div>
            <div class="mb-4">
                <div class="d-flex justify-content-between">
                    <label class="form-label" for="password">Password</label>
                    <a class="text-white" [routerLink]="['/remind-password']" tabindex="-1"
                        ><small>Don't remember?</small></a
                    >
                </div>
                <div class="input-container">
                    <input
                        class="form-control py-2 border-0"
                        [type]="showPassword ? 'text' : 'password'"
                        id="password"
                        formControlName="password"
                        [ngClass]="{
                            'is-invalid': form.controls.password.touched && form.controls.password.errors
                        }"
                        required
                    />
                    <div class="invalid-feedback" *ngIf="form.controls.password.hasError('required')">
                        Required field
                    </div>
                    <span class="eye-icon" (click)="onEyeClicked()"></span>
                </div>
            </div>
            <button class="btn py-2 btn-success w-100 mt-4 mb-3" type="submit" [disabled]="!form.valid">
                <span *ngIf="isLoading" class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </span>
                <span *ngIf="!isLoading">Sign In</span>
            </button>
            <p class="text-center mt-4">
                Not a member? <a class="text-white" [routerLink]="['/sign-up']">Create an account for free</a>
            </p>
        </form>
    </div>
</div>
