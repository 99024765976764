import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Token } from '@/enums';
import { APP_CONFIG } from '@/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private tokenSubject: BehaviorSubject<string | null>;
    public token$: Observable<string | null>;

    constructor(private http: HttpClient) {
        this.tokenSubject = new BehaviorSubject<string | null>(
            localStorage.getItem(Token.ACCESS) || localStorage.getItem(Token.REFRESH) || null,
        );
        this.token$ = this.tokenSubject.asObservable();
    }

    public get token(): string | null {
        return this.tokenSubject.value;
    }

    setToken(token: string) {
        this.tokenSubject.next(token);
    }

    login({ email, password, silent }: { email: string; password: string; silent?: boolean }) {
        return this.http
            .post<any>(`${APP_CONFIG.apiUrl}/auth/login`, { email, password }, { withCredentials: true })
            .pipe(
                map(
                    (response: {
                        data: {
                            refresh_token: string;
                            access_token: string;
                        };
                    }) => {
                        if (!silent) {
                            this.tokenSubject.next(response.data.access_token);
                            localStorage.setItem(Token.ACCESS, response.data.access_token);
                            localStorage.setItem(Token.REFRESH, response.data.refresh_token);
                        }
                    },
                ),
            );
    }

    refresh() {
        return this.http
            .post<any>(`${APP_CONFIG.apiUrl}/auth/refresh`, {
                refresh_token: localStorage.getItem(Token.REFRESH),
                mode: 'json',
            })
            .pipe(
                map(
                    (response: {
                        data: {
                            refresh_token: string;
                            access_token: string;
                        };
                    }) => {
                        this.tokenSubject.next(response.data.access_token);
                        localStorage.setItem(Token.ACCESS, response.data.access_token);
                        localStorage.setItem(Token.REFRESH, response.data.refresh_token);
                    },
                ),
            );
    }

    register({ email, password }: { email: string; password: string }) {
        return this.http.post<any>(`${APP_CONFIG.apiUrl}/users`, { email, password }, { withCredentials: true });
    }

    remindPassword(email: string) {
        return this.http.post<any>(`${APP_CONFIG.apiUrl}/auth/password/request`, { email }, { withCredentials: true });
    }

    resetPassword({
        password,
        passwordConfirmation,
        token,
    }: {
        password: string;
        passwordConfirmation: string;
        token: string;
    }) {
        return this.http.post<any>(
            `${APP_CONFIG.apiUrl}/auth/password/reset`,
            { password, passwordConfirmation, token },
            { withCredentials: true },
        );
    }

    logout() {
        localStorage.removeItem(Token.ACCESS);
        localStorage.removeItem(Token.REFRESH);
        this.tokenSubject.next(null);
    }
}
